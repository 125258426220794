<template>
    <div class="rich-text simple-richtext" :class="[DesktopTextAlign, MobileTextAlign]" :style="paragraphBottomIndent">
        <rich-text :content="blok.text" />
    </div>
</template>

<script setup lang="ts">
import type { VNode } from 'vue';
import { createTextVNode, h } from 'vue';
import {
    BlockTypes,
    richTextResolver,
    type StoryblokRichTextNode,
    type StoryblokRichTextOptions,
} from '@storyblok/richtext';

import type { ISimpleRichtextConfig } from '~ui/types/components/SimpleRichtext';

const props = defineProps<{ blok: ISimpleRichtextConfig }>();

const MobileTextAlign = computed(() => {
    return {
        'left-mobile': props.blok.MobileTextAlign === 'left',
        'center-mobile': props.blok.MobileTextAlign === 'center',
        'right-mobile': props.blok.MobileTextAlign === 'right',
    };
});

const DesktopTextAlign = computed(() => {
    return {
        'text-left-align': props.blok.textAlign === 'text-left',
        'text-center-align': props.blok.textAlign === 'text-center',
        'text-right-align': props.blok.textAlign === 'text-right',
    };
});

const paragraphBottomIndent = computed(
    () =>
        `${props.blok.ParagraphBottomIndent ? `--paragraph-bottom-indent: ${props.blok.ParagraphBottomIndent}px;` : '--paragraph-bottom-indent: 1rem;'}`
);

const options: StoryblokRichTextOptions<VNode> = {
    renderFn: h,
    textFn: createTextVNode,
    resolvers: {
        [BlockTypes.COMPONENT]: (node: StoryblokRichTextNode<VNode>) => {
            return node?.attrs?.body.map((blok) => {
                switch (blok.component) {
                    case 'OTCookieButton': {
                        return h(
                            'p',
                            {
                                innerHTML: `<button id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie Settings</button>`,
                            },
                            node.children
                        );
                    }
                    case 'OTCookieTables': {
                        return h('div', { id: 'ot-sdk-cookie-policy' }, node.children);
                    }
                    case 'CheckmarkList': {
                        const cssStyles = `--checkmark-size: ${blok?.checkmarkSize}px;--checkmark-list-font-size: ${blok?.fontSize}px;`;
                        let listItems = '';
                        blok?.listItems?.forEach((item) => {
                            listItems += `<li>
                            <svg class="checkbox-circle-line" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2S2 6.477 2 12s4.477 10 10 10m5.457-12.543L11 15.914l-4.207-4.207l1.414-1.414L11 13.086l5.043-5.043z"/></svg>
                            ${item.text}
                        </li>`;
                        });
                        return h(
                            'ul',
                            { class: 'richtext-checkmark-list', style: cssStyles, innerHTML: listItems },
                            node.children
                        );
                    }
                    default: {
                        return 'Resolver not defined: ' + blok.component;
                    }
                }
            });
        },
    },
};

const richText = (content: any) => {
    return richTextResolver<VNode>(options).render(content.content);
};
</script>

<style scoped lang="postcss">
.simple-richtext {
    &.left-mobile {
        :deep(p) {
            @media screen and (max-width: 768px) {
                @apply text-left;
            }
        }
    }
    &.center-mobile {
        :deep(p) {
            @media screen and (max-width: 768px) {
                @apply text-center;
            }
        }
    }
    &.right-mobile {
        :deep(p) {
            @media screen and (max-width: 768px) {
                @apply text-right;
            }
        }
    }
    &.text-left-align {
        @apply text-left;
    }
    &.text-center-align {
        @apply text-center;
    }
    &.text-right-align {
        @apply text-right;
    }
}
</style>
